import React from "react";

import { Approving } from "../organisms/Bid.styles";
import CircularProgress from "@mui/material/CircularProgress";

import { BeesLoader } from "../atoms";

const ApprovalBody = () => {
  return (
    <Approving>
      {/* <CircularProgress /> */}
      <BeesLoader />

      <p>Waiting for approval</p>

      <small>
        Please give permission to access your <strong>BHNY</strong> in your
        wallet.
      </small>
    </Approving>
  );
};

export default ApprovalBody;
