import { useEffect, useState } from "react";
import moment from "moment";
import { FiClock } from "react-icons/fi";
import * as ethers from "ethers";
import config from "../../config";
import { Button, CountDown } from "../atoms";
import { IAccount } from "../../types";
import { AuctionBee } from ".";
import CircularProgress from "@mui/material/CircularProgress";
import { RPC_URLS, SupportedChainId } from '../../constants/chains';
declare var window: any;
interface Props {
  account: any;
  getBees: any;
  setIsAuctionEnded: any;
  canExecute: boolean;
  auctionDay: number;
  getAuctionDay: any;
  setCanExecute: any;
  timeEnd: number;
  setIsExecutionTime: any;
  bees: any[];
  handleWalletOpen: any;
  handleAuctions: any;
  setHandleAuctions: any;
  setLoad: any;
  timeBeforeAuction: any
}

const AuctionEnded = ({
  account,
  getBees,
  setIsAuctionEnded,
  canExecute,
  auctionDay,
  getAuctionDay,
  setCanExecute,
  timeEnd,
  setIsExecutionTime,
  bees,
  handleWalletOpen,
  handleAuctions,
  setHandleAuctions,
  setLoad,
  timeBeforeAuction
}: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<any>(0);
  const [timeRemainSeconds, setTimeRemainSeconds] = useState<any>(0);
  const [start, setStart] = useState(false);
  const [lastAuction, setLastAuction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextExecutionTime, setNextExecutionTime] = useState(0);
  const [actual, setActual] = useState(0);

  const getCurrentDayElapsedTime = async () => {
    let provider = null;
    let contract = null;

    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );
    }
    try {
      const currentDayElapsedTime = await contract.prevElapsedTime();

      console.log("auction end", currentDayElapsedTime.toString());

      const timeLeft = Number(currentDayElapsedTime.toString()) - 82800;

      console.log("currentDayElapsedTime", currentDayElapsedTime.toString());

      const onehour = Math.round(Date.now() / 1000) + 3600;

      setTimeRemaining(onehour - timeLeft);
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "http://localhost:8545"
      // );
      // const blockNumBefore = await provider.getBlockNumber();
      // const actual = (await provider.getBlock(blockNumBefore)).timestamp;
      const actual = Math.round(Date.now() / 1000);
      const nextExecutiontime = await contract.nextExecutionTime();
      setNextExecutionTime(Number(nextExecutiontime.toString()));
      setTimeRemainSeconds(Number(nextExecutiontime.toString()) - actual)
      setActual(actual)

    } catch (error) {
      console.log("getCurrentDayElapsedTime", error);
    }
  };

  const _execute = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);

    const signer = await provider.getSigner();

    const day = auctionDay === 1 ? auctionDay : auctionDay;

    setLoading(true);

    try {
      const contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        signer
      );
      const exe = await contract.execute({ gasLimit: 1500000 });
      console.log("transaction info", exe);
      listenExecute();
    } catch (err) {
      // console.log(err);
      console.log(err);
    }
  };

  function listenExecute() {
    try {
      console.log("Listener started");
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "https://rinkeby.infura.io/v3/799ad57d4067419db57bf9592b471301"
      // );
      const provider = new ethers.providers.Web3Provider(
        window.ethereum as any
      );
      const auctionContract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );

      const filter = auctionContract.filters.Executed();

      console.log("watching for executed events...");
      // Receive an event when that filter occurs
      auctionContract.on(filter, async (from, to, amount, event, unknow) => {
        setCanExecute(false);
        const day = await getAuctionDay();
        await getBees(day);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCurrentDayElapsedTime();

    const _lastDay = moment.unix(timeEnd).format("MMMM D YYYY");
    const currentDay = moment().format("MMMM D YYYY");

    if (_lastDay === currentDay) {
      setLastAuction(true);
    }
  }, []);

  const getNewAuction = async () => {
    const day = await getAuctionDay();
    await getBees(day);
    window.location.reload();
    console.log("getNewAuction");
  };

  useEffect(() => {
    if (start) {
      setIsAuctionEnded(false);
      setIsExecutionTime(false);
      getNewAuction();
    }
  }, [start]);

  return (
    <div className='auction-section__content__ended'>
      {!lastAuction && (
        <>
          {timeRemainSeconds < 0 ?

            <header>Next Auction starts when transfer completes</header>
            :
            <>
              <header>Next Auction starts in</header>

              <div className='time-remaining'>
                <FiClock />

                {timeRemaining !== 0 && (
                  <CountDown
                    targetDate={moment.unix(timeRemaining)}
                    setCountDownEnded={setStart}
                    block={actual}
                    next={nextExecutionTime}
                  />
                )}
              </div>
            </>
          }
        </>
      )}

      {/*canExecute && (
        <div className='execute'>
          <header>Help the BEES fly away</header>

          <Button
            tooltip
            tooltipText='Click the button to help transfer the past auction BEES to their
          winner. In exchange you’ll get some BHNY!'
            onClick={_execute}
          >
            {loading ? <CircularProgress /> : "Transfer last auction"}
          </Button>
        </div>
      )*/}

      <div className='auction-section__content__ended__content'>
        {bees.length > 0 &&
          bees.map((b: number, index) => {
            return (
              <AuctionBee
                ended={true}
                setLoading={setLoad}
                fee={"0"}
                bees={bees}
                handleWalletOpen={handleWalletOpen}
                key={`bee${index * Date.now()}`}
                bee={b}
                idx={index}
                handleAuctions={handleAuctions}
                auctionDay={auctionDay}
                setHandleAuctions={setHandleAuctions}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AuctionEnded;
