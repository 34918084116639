import React, { useState, useEffect, memo } from "react";
import placeholder from "../../assets/placeholder-bee.png";
import { Button } from "../atoms";
import BidModal from "./Bidmodal";
import config from "../../config";
import * as ethers from "ethers";
import axios from "axios";

import { useAccount } from "../../hooks/useAccount";
import { IAccount } from "../../types";

import { WalletModal } from "../molecules";

import { AUCTION_DAY } from "../../constants";
import { RPC_URLS, SupportedChainId } from '../../constants/chains';

interface AuctionProps {
  bee: number;
  idx: number;
  auctionDay: number;
  handleAuctions: any;
  setHandleAuctions: any;
  handleWalletOpen: any;
  fee: string;
  setLoading: any;
  bees: any;
  ended?: boolean;
}

const AuctionBee: React.FC<AuctionProps> = ({
  bee,
  idx,
  auctionDay,
  handleAuctions,
  setHandleAuctions,
  handleWalletOpen,
  fee,
  setLoading,
  bees,
  ended,
}) => {
  const [open, setOpen] = useState(false);

  const [amount, setAmount] = useState("0");
  const [bids, setBids] = useState<any[]>([]);
  const [currentBid, setCurrentBid] = useState("0");
  const [refreshBids, setRefreshBids] = useState("");
  const _account: IAccount = useAccount();
  const [walletOpen, setWalletOpen] = useState(false);
  const [accruedYield, setAccruedYield] = useState("-");
  const [trait, setTrait] = useState("");
  const [winner, setWinner] = useState("");
  const [count, setCount] = useState(0);
  const { ...account } = _account as any;

  //  --just for test--
  // const mintWPE = async () => {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum as any);

  //   const signer = provider.getSigner();

  //   const wpeContract = new ethers.Contract(
  //     config.wpeAddress,
  //     config.wpeAbi,
  //     signer
  //   );

  //   try {
  //     await wpeContract.mint();
  //     await wpeContract.mint();
  //     await wpeContract.mint();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const getBids = async () => {
    let provider = null;
    let contract = null;
    const isOld = handleAuctions <= 0;
    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        isOld ? config.oldAuctionAddress : config.auctionAddress,
        isOld ? config.oldAuctionAbi : config.auctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
      contract = new ethers.Contract(
        isOld ? config.oldAuctionAddress : config.auctionAddress,
        isOld ? config.oldAuctionAbi : config.auctionAbi,
        provider
      );
    }

    try {
      const day = isOld ? handleAuctions + 131 : handleAuctions;
      const _bids = await contract.getBids(day, idx);

      if (_bids.length > 0) {
        setWinner(_bids[_bids.length - 1][0]._hex);
        setCurrentBid(ethers.utils.formatEther(_bids[_bids.length - 1][1]));
      } else {
        setWinner("");
        setCurrentBid("0");
      }

      const __bids = [..._bids].reverse();

      setBids(__bids);
    } catch (error) {
      console.error(error);
    }
  };

  const getAccruedYield = async () => {
    let provider = null;
    let contract = null;

    try {
      if (account.account.address !== "" && window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum as any);
        contract = new ethers.Contract(
          config.honeyBeeLockUpAddress,
          config.honeyBeeLockUpABI,
          provider.getSigner()
        );
      } else {
        provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;

        contract = new ethers.Contract(
          config.honeyBeeLockUpAddress,
          config.honeyBeeLockUpABI,
          provider
        );
      }

      const earned = await contract.earned(bee);

      setAccruedYield(ethers.utils.formatUnits(earned, "gwei"));
    } catch (err) {
      // console.log(err);
    }
  };

  const onTransfer = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);

    const signer = provider.getSigner();

    const auctionContract = new ethers.Contract(
      config.auctionAddress,
      config.auctionAbi,
      signer
    );

    try {
      const tx = await auctionContract.transferOwnership(
        account.account.address
      );

      console.log(tx);

      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBids();
    getBeeTrait();
    getAccruedYield();
  }, []);

  const getBeeTrait = async () => {
    try {
      const res = await axios.get(
        `https://socialbees-mint.s3.amazonaws.com/${bee}`
      );

      const attributes = res.data.attributes;

      const country = attributes.find(
        (attr: any) => attr.trait_type === "PRINCESS BEE" || attr.trait_type === "ROYAL BEE"
      );

      const base = attributes.find((attr: any) => attr.trait_type === "BASE");

      const headware = attributes.find(
        (attr: any) => attr.trait_type === "HEADWARE" && attr.value === "Crown"
      );

      const _trait = country
        ? country.value
        : headware
          ? headware.value
          : base
            ? base.value
            : "";

      setTrait(_trait);
    } catch (err) { }
  };

  const getEmpowerMint = async () => { };

  return (
    <>
      <div className='bee'>
        <div className='bee__img'>
          <img
            src={`https://socialbees-mint-img.s3.amazonaws.com/${bee}.png`}
            alt={`bee-${bee}`}
          />
          {handleAuctions + 131 < 270 ? ( 
            <div>
            <img
              className='bee__img__empowermint'
              src={`https://socialbees-mint-img.s3.amazonaws.com/${Number(bee) + 1
                }.png`}
              alt={`EmpowetMintBee-${Number(bee) + 1}`}
            />
            <small>
              EMPOWERMINT
            </small>
            </div>
          ) : null}
        </div>

        <div className={handleAuctions + 131 < 270 ? ('bee__info') : ('bee__infoMain')}>
          <strong className='trait'>{trait}</strong>

          <small>Current Bid</small>
          <p>{currentBid} BHNY</p>

          {handleAuctions !== auctionDay || ended ? (
            <small>
              <strong>winner : </strong>

              {winner === "" ? (
                "SBU DAO"
              ) : (
                <a
                  href={`https://opensea.io/${winner}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='winner'
                >
                  {winner.substr(0, 5)}...
                  {winner.substr(winner.length - 4, winner.length)}
                </a>
              )}
            </small>
          ) : null}
        </div>

        {handleAuctions !== auctionDay || ended ? null : (
          <div className='bee__auction'>
            <Button
              onClick={() =>
                account.account.address !== ""
                  ? setOpen(true)
                  : handleWalletOpen()
              }
            >
              Place Bid
            </Button>
          </div>
        )}
      </div>

      {!ended && (
        <BidModal
          fee={fee}
          open={open}
          bee={bee}
          idx={idx}
          setOpen={() => setOpen(false)}
          bids={bids}
          currentBid={currentBid}
          auctionDay={auctionDay}
          handleAuctions={handleAuctions}
          getBids={getBids}
        />
      )}
    </>
  );
};

// export default AuctionBee;

const AuctionBeeMemo = memo(AuctionBee);

export default AuctionBeeMemo;
