import { useState, useEffect } from "react";

import { AuctionBee } from "../organisms";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoLogoHackernews,
} from "react-icons/io";
import { FiClock } from "react-icons/fi";
import { BidModal } from "../organisms";
import { AUCTION_DAY } from "../../constants";
import { Button, BeesLoader } from "../atoms";
import moment from "moment";

import { useAccount } from "../../hooks/useAccount";
import config from "../../config";
import * as ethers from "ethers";
import { CountDown } from "../atoms";
import { WalletModal } from "../molecules";

import { AuctionStart, AuctionEnded } from "../organisms";
import { last } from 'lodash';
import axios from 'axios';
import { RPC_URLS, SupportedChainId } from '../../constants/chains';

declare var window: any;

const Auction = () => {
  const [bees, setBees] = useState<any[]>([]);
  const [auctionDay, setAuctionDay] = useState(-999);
  const [handleAuctions, setHanldeAuctions] = useState(-999);
  const [fee, setFee] = useState("0");

  const [auctionStart, setAuctionStart] = useState(false);
  const [IsAuctionEnded, setIsAuctionEnded] = useState(false);
  const [auctionTime, setAuctionTime] = useState<any>(0);

  const [auctionEnd, setAuctionEnd] = useState<any>(0);
  const [walletOpen, setWalletOpen] = useState(false);
  const handleWalletOpen = () => setWalletOpen(!walletOpen);
  const [canExecute, setCanExecute] = useState(false);
  const [timeStart, setTimeStart] = useState(0);
  const [timeEnd, setTimeEnd] = useState(0);
  const [timeBeforeAuction, setTimeBeforeAuction] = useState(0);
  const [daoTreasury, setDaoTreasury] = useState("");
  const [trcTreasury, setTrcTreasury] = useState("");
  // const [auctionCondition, setAuctionCondition] = useState(false);
  const [nextExecutionTime, setNextExecutionTime] = useState(0);
  const [actual, setActual] = useState(0);

  const [data, setData] = useState<any>({});
  const [burnData, setBurnData] = useState<any>(null);

  const [loading, setloading] = useState(false);

  const _account = useAccount();

  const [isExecutionTime, setIsExecutionTime] = useState<any>(null);
  const { ...account } = _account as any;

  useEffect(() => {
    getAuctionDay();
  }, [account.account.address]);

  useEffect(() => {
    setBees([]);
    if (isExecutionTime !== null) {
      if ((handleAuctions > -131 && auctionDay > -999) || (isExecutionTime && handleAuctions === auctionDay)) {
        getBees(handleAuctions);
      }
    }
  }, [handleAuctions, auctionDay, isExecutionTime, canExecute]);

  // useEffect(() => {
  //   setBees([]);

  //   // if (handleAuctions > -131) {
  //   if (auctionDay > -999) {
  //     getBees(auctionDay);
  //   }
  //   // }
  // }, [auctionDay]);

  useEffect(() => {
    if (bees.length === 0) {
      setloading(true);
    } else {
      setTimeout(() => {
        setloading(false);
      }, 1000);
    }
    remainingTime();
    getFee();
    treasuries();
  }, [bees]);

  useEffect(() => {
    // runAuctionCondition();
    if (auctionTime !== 0) {
      _canExecute(auctionTime);
    }
  }, [auctionTime]);

  const getBees = async (day: number) => {
    let provider = null;
    let contract = null;
    let oldContract = null;

    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider.getSigner()
      );
      oldContract = new ethers.Contract(
        config.oldAuctionAddress,
        config.oldAuctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );
      oldContract = new ethers.Contract(
        config.oldAuctionAddress,
        config.oldAuctionAbi,
        provider
      );
    }

    try {
      if (day > 0) {
        console.log(isExecutionTime)
        console.log(handleAuctions, auctionDay)
        console.log(canExecute)
        const lastDay = (isExecutionTime && handleAuctions === auctionDay && !canExecute) ? day - 1 : day;
        const bees = await contract.getBeesByDay(lastDay);
        const beesIDs = bees.map((b: any) => b.toNumber());
        setBees(beesIDs);
      }
      else {
        const bees = await oldContract.getBeesByDay(day + 131);
        const beesIDs = bees.map((b: any) => b.toNumber());
        setBees(beesIDs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAuctionDay = async () => {
    let provider = null;
    let contract = null;

    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );
    }

    try {
      const _auctionDay = await contract.getCurrentDay();
      setAuctionDay(_auctionDay.toNumber());
      setHanldeAuctions(_auctionDay.toNumber());
      return _auctionDay.toNumber();
    } catch (error) {
      console.error(error);
    }
  };

  const getFee = async () => {
    let provider = null;
    let contract = null;

    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(
        "https://mainnet.infura.io/v3/6d07c9b168114102921f5ab6808ef17f"
      );
      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );
    }

    try {
      const _fee = await contract.fee();

      setFee(ethers.utils.formatEther(_fee.toString()));
    } catch (error) {
      console.error(error);
    }
  };

  const changeAuction = (direction: string) => {
    if (direction === "prev") {
      if (handleAuctions === -130) return;

      setHanldeAuctions(handleAuctions - 1);
    } else if (direction === "next" && handleAuctions < auctionDay) {
      setHanldeAuctions(handleAuctions + 1);
    }
  };

  const _canExecute = (lastExecutionTime: number) => {
    const time = Math.round(Date.now() / 1000);
    if (time - lastExecutionTime > 0 && isExecutionTime) {
      setCanExecute(true);
    } else {
      setCanExecute(false);
    }
  };

  const treasuries = async () => {
    let provider = null;
    let contract = null;

    provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
    contract = new ethers.Contract(
      config.bhnyAddress,
      config.bhnyAbi,
      provider
    );

    try {
      const daoTreasury = await contract.balanceOf("0x088D95AE734091d4e3530302603b16B2A7457F9D");
      setDaoTreasury(Math.trunc(daoTreasury / 10e17).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

      const tcrTreasury = await contract.balanceOf("0x2D4FA4d188774e10CE6a322B38250655772E193D");
      setTrcTreasury((Math.trunc(tcrTreasury / 10e17)+1192783).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    } catch (error) {
      console.log(error);
    }
  }

  const remainingTime = async () => {
    let provider = null;
    let contract = null;

    if (account.account.address !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);

      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.MAINNET]) as any;
      contract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );
    }

    try {
      const lastExecutiontime = await contract.lastExecutionTime();
      const nextExecutiontime = await contract.nextExecutionTime();
      const lastExecuted = await contract.lastDayExecuted();
      const diffActual = 1665604832 - Math.round(Date.now() / 1000);//test
      const date = moment.unix(lastExecutiontime.toString());
      // let date = Number(lastExecutiontime.toString()) + (23 * 3600)
      const timeStart = await contract.timeStart();
      const timeEnd = await contract.timeEnd();

      const prev = await contract.prevElapsedTime();
      const current = await contract.currentDayElapsedTime();

      const diff2 = prev.toNumber() - 82800;
      const onehour = Math.round(Date.now() / 1000) + 3600;
      const execTime = Number(nextExecutiontime.toString()) - Math.round(Date.now() / 1000);
      const auctionTime = Math.round(Date.now() / 1000) - Number(lastExecutiontime.toString());
      setTimeBeforeAuction(onehour - diff2)
      // if (((onehour - diff2 > 0) && (prev.toNumber() !== current.toNumber())) || (execTime <= 3600 || auctionTime < 0)) {
      if ((execTime <= 3600 || auctionTime < 0)) {
        setIsExecutionTime(true);
      } else {
        setIsExecutionTime(false)
      }
      setActual(actual)
      setTimeEnd(Number(timeEnd.toString()));
      setAuctionEnd(date.add(23, "hours"));//test
      // setAuctionEnd(date.add(23, "hours"));
      // setAuctionEnd(moment.unix(auctionEnds));
      setTimeStart(Number(timeStart.toString()));
      setAuctionTime(Number(lastExecutiontime.toString()));
      setNextExecutionTime(Number(nextExecutiontime.toString()));
    } catch (error) {
      console.log(error);
    }
  };

  const getBurnData = async () => {
    try {
      axios.get("https://socialbees.io/api/burn").then((response) => {
        setBurnData(response.data)
      })
    } catch {
      console.log("error")
    }
  }

  const auctionCondition = () => {
    const actual = moment().unix();
    const auctionendtime = moment(auctionEnd).unix();
    const start = moment(timeStart).unix();
    // console.log("auctionend", IsAuctionEnded)
    // console.log("ex", isExecutionTime)
    // console.log("handle", handleAuctions)
    // console.log("day", auctionDay)
    // console.log("act", actual)
    // console.log("auctionen", auctionendtime)
    // console.log("start", start)
    // console.log("endtiem", actual > auctionendtime)
    // console.log("endtiem", actual > auctionendtime)
    // console.log("remains", timeBeforeAuction - actual)
    // console.log("next", nextExecutionTime)
    if (
      // ((IsAuctionEnded || actual > auctionendtime) && handleAuctions === auctionDay) ||
      // (((isExecutionTime && actual > auctionendtime) || (timeBeforeAuction - actual > 0 && timeBeforeAuction - actual <= 3600)) && handleAuctions === auctionDay)
      (IsAuctionEnded && handleAuctions === auctionDay) ||
      (isExecutionTime && handleAuctions === auctionDay)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getBurnData();
    setTimeout(function () {
      getBurnData()
    }, 4 * 3600 * 1000);
  }, []);
  //for test
  // const mintWPE = async () => {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum as any);

  //   const signer = provider.getSigner();

  //   const wpeContract = new ethers.Contract(
  //     config.wpeAddress,
  //     config.wpeAbi,
  //     signer
  //   );

  //   try {
  //     await wpeContract.mint();
  //     await wpeContract.mint();
  //     await wpeContract.mint();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <>
      <section className='auction-section'>
        {auctionStart && (
          <header className='auction-section__header'>
            <section className='auction-section__header__treasuries'>
              <div className='treasury'>
                <a
                  style={{ color: "inherit", textDecoration: " none" }}
                  href='https://etherscan.io/tokenholdings?a=0x088D95AE734091d4e3530302603b16B2A7457F9D'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  DAO Treasury - {daoTreasury} BHNY
                </a>
              </div>
              <div className='treasury'>
                {/*<a
                  style={{ color: "inherit", textDecoration: " none" }}
                  href='https://etherscan.io/tokenholdings?a=0x2D4FA4d188774e10CE6a322B38250655772E193D'
                  target='_blank'
                  rel='noopener noreferrer'>*/}
                  Queen Tax Vault - {trcTreasury} BHNY
                {/*</a>*/}
              </div>
              {burnData && <div className='burn'>
                <div className='item'>
                  <small>Current Supply</small>
                  <span>
                    <img src={require('../../assets/bhny.png').default} alt="" />
                    {burnData?.bhny_circulating_supply?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || 0}
                  </span>
                </div>
                <div className='item'>
                  <small>Circulating</small>
                  <span>
                    <img src={require('../../assets/bhny.png').default} alt="" />
                    {(burnData?.bhny_circulating_supply -9506149).toLocaleString('en-US', { maximumFractionDigits: 0 }) || 0}
                  </span>
                </div>
                <div className='item'>
                  <small>Total Burn</small>
                  <span>
                    <img src={require('../../assets/burn.png').default} alt="" />
                    {(31450000 - burnData.bhny_circulating_supply).toLocaleString('en-US', { maximumFractionDigits: 0 }) || 0}
                  </span>
                </div>
                <div className='item'>
                  <small>24h burn</small>
                  <span>
                    <img src={require('../../assets/burn.png').default} alt="" />
                    {burnData?.last_burned?.burned?.toLocaleString('en-US', { maximumFractionDigits: 0 }) || 0}
                  </span>
                </div>
              </div>}
              {/* <Button onClick={mintWPE}>mint BHNY</Button> */}
            </section>
            <section className='auction-section__header__down'>
              <div className='left-header'>
                <div className='handleAuctions'>
                  {handleAuctions !== -130 && (
                    <div
                      className='prev-auction'
                      onClick={() => changeAuction("prev")}
                    >
                      <div className='back'>
                        <IoIosArrowBack />
                      </div>
                      Previous Auction
                    </div>
                  )}
                  {handleAuctions < auctionDay && (
                    <div
                      className='prev-auction'
                      onClick={() => changeAuction("next")}
                    >
                      Next Auction
                      <div className='back'>
                        <IoIosArrowForward />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div></div>

              {auctionCondition() ||
                handleAuctions < auctionDay
                ? (
                  <div></div>
                ) : (
                  <div className='time-remaining'>
                    <header className='time-remaining__header'>
                      <small>Auction ends in</small>
                    </header>
                    <div className='time-remaining__time'>
                      <FiClock />
                      {auctionEnd !== 0 ? (
                        <CountDown
                          targetDate={auctionEnd}
                          setCountDownEnded={setIsAuctionEnded}
                          handleAuctions={handleAuctions}
                          setHandleAuctions={setHanldeAuctions}
                          currentAuctionDay={auctionDay}
                          countDownEnded={IsAuctionEnded}
                          block={actual}
                          next={nextExecutionTime}
                        />
                      ) : (
                        <p>0h 0m 0s</p>
                      )}
                    </div>
                    <footer className='time-remaining__date'>
                      {auctionEnd !== 0 && (""
                        // <small>{auctionEnd.format("MMMM DD,YYYY")}</small>
                      )}
                    </footer>
                  </div>
                )}
            </section>

            <section
              style={{
                marginTop: "20px",
              }}
            >
              <strong
                style={{
                  color: "#f6a917",
                }}
              >
                Auction:
              </strong>{" "}
              {handleAuctions + 131}
            </section>
          </header>
        )}

        {loading ? (
          <section className='loader-container'>
            <BeesLoader />
          </section>
        ) : (
          <div className='auction-section__content'>
            {!auctionStart ? (
              <AuctionStart
                timeStart={timeStart}
                firstAuctionStart={auctionStart}
                setFirstAuctionStart={setAuctionStart}
              />
            ) : (
              <>
                {auctionCondition() ? (
                  <AuctionEnded
                    bees={bees}
                    setIsAuctionEnded={setIsAuctionEnded}
                    getBees={getBees}
                    account={account}
                    setLoad={setloading}
                    canExecute={canExecute}
                    auctionDay={auctionDay}
                    timeEnd={timeEnd}
                    getAuctionDay={getAuctionDay}
                    setCanExecute={setCanExecute}
                    handleWalletOpen={handleWalletOpen}
                    handleAuctions={handleAuctions}
                    setHandleAuctions={setHanldeAuctions}
                    setIsExecutionTime={setIsExecutionTime}
                    timeBeforeAuction={timeBeforeAuction}
                  />
                ) : (
                  <div className='auction-section__content__bees'>
                    {bees.length > 0 &&
                      bees.map((b: number, index) => {
                        return (
                          <AuctionBee
                            setLoading={setloading}
                            fee={fee}
                            bees={bees}
                            handleWalletOpen={handleWalletOpen}
                            key={`bee${index * Date.now()}`}
                            bee={b}
                            idx={index}
                            handleAuctions={handleAuctions}
                            setHandleAuctions={setHanldeAuctions}
                            auctionDay={auctionDay}
                          />
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </section>



      <footer className='auction-section__footer'>
        <p>
          How would you determine the value of SBUDAO Defi 3.0 tech stack?  <br />
          All the technology and economics are built into our Social BEES NFT.
        </p>
        <div className='view-more'>
          <span>
            <a
              style={{ color: "inherit", textDecoration: " none" }}
              href='https://sbudao.notion.site/Social-BEES-University-DAO-7211d88acfb2413991a98dc1364b77c1'
              target='_blank'
              rel='noopener noreferrer'
            >
              View More
            </a>
          </span>
        </div>
      </footer>

      <WalletModal open={walletOpen} handleClose={handleWalletOpen} />
    </>
  );
};

export default Auction;

// rsync -azP -e "ssh -i socialbees.pem" build ubuntu@44.202.37.162:~/app
