import React from "react";
import { ReactComponent as Logo } from "../../assets/bees-logo.svg";

const BeesLoader = () => {
  return (
    <section className='container'>
      <div className='div'>
        <div className='div'>
          <span className='one h6'></span>
          <span className='two h3'></span>
        </div>
      </div>

      <div className='div'>
        <div className='div'>
          <span className='one h1'></span>
          <span className='two h4'></span>
        </div>
      </div>

      <Logo />

      <div className='div'>
        <div className='div'>
          <span className='one h5'></span>
          <span className='two h2'></span>
        </div>
      </div>

      {/* <strong className='caption'>Loading....</strong> */}
    </section>
  );
};

export default BeesLoader;
