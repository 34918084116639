import React from "react";
import { Header, Footer, SideBar } from "./components/templates";
import { ScrollingProvider, Section } from "react-scroll-section";
import Web3 from "web3";
import { Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { injected } from "./connectors";
import { IAccount } from "./types";
import { setAccount } from "./store/actions/account";
import { configure } from "./lib/utils";
import { useMediaQuery } from "react-responsive";
import { useAccount } from "./hooks/useAccount";

import { useWeb3React } from "@web3-react/core";

import { Alert } from "./components/atoms";

import { connectOnMount } from "./lib/utils";

import { Auction, SBU, Slider, Honey } from "./components/sections";

const App = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const _setAccount = React.useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );

  const { activate } = useWeb3React();
  const _account = useAccount();
  const { ...account } = _account as any;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  React.useEffect(() => {
    const key = localStorage.getItem("wconnected");

    connectOnMount(key, activate, _setAccount);
  }, []);

  return (
    <ScrollingProvider>
      <div className='App' id='App'>
        {isTabletOrMobile && (
          <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
        )}
        <Header />
        <div id='page-wrap'>
          <Section id='auction'>
            <Auction />
          </Section>
          <Section id='sbu'>
            <SBU />
          </Section>

          <Section id='slider'>
            <Slider />
          </Section>

          <Section id='honey'>
            <Honey />
          </Section>
        </div>
        <Footer />
        <Alert />
      </div>
    </ScrollingProvider>
  );
};

export default App;
