import { slide as Menu } from "react-burger-menu";
import { useState, useCallback, useEffect, FC } from "react";
import { Button } from "../atoms";
import { useScrollSection } from "react-scroll-section";
import { RiTwitterFill, RiDiscordFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";
import { ReactComponent as OpenSeaLogo } from "../../assets/opensea-logo.svg";
import { ReactComponent as Logo } from "../../assets/bees-logo.svg";

import { useDispatch } from "react-redux";
import { setAccount } from "../../store/actions/account";
import { WalletModal } from "../molecules";
import { IAccount } from "../../types";
import { Dispatch } from "redux";
import { useAccount } from "../../hooks/useAccount";
import { useGetEthBalance } from "../../hooks/useGetEthBalance";
import { parseNumbers } from "../../utils/currency";

const SideBar = (props: any) => {
  let _account: IAccount = useAccount();
  const { ...account } = _account as any;
  const [ethBalance, setEthBalance] = useState("0.0");
  const dispatch: Dispatch<any> = useDispatch();
  const balance = useGetEthBalance();
  const _setAccount = useCallback(
    (account: IAccount) => dispatch(setAccount(account)),
    [dispatch]
  );
  const handleWalletModal = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const auctionSection = useScrollSection("auction");

  async function _setEthBalance() {
    const b: any = await balance;
    setEthBalance(parseNumbers(b));
  }

  useEffect(() => {
    if (account.account.address !== "") {
      _setEthBalance();
    }
  });

  return (
    // Pass on our props
    <Menu {...props} right>
      <a
        href='https://dao.socialbees.io'
        target='_blank'
        rel='noopener noreferrer'
      >
        <li>DAO</li>
      </a>
      <a
        href='https://sbudao.notion.site/Social-BEES-University-DAO-7211d88acfb2413991a98dc1364b77c1'
        target='_blank'
        rel='noopener noreferrer'
      >
        <li>DOCS</li>
      </a>
      <li onClick={auctionSection.onClick}>BUY BHNY</li>
      {/* <li onClick={auctionSection.onClick}>BUY HONEYPOTS</li> */}
      <a
        href='https://myhive.socialbees.io'
        target='_blank'
        rel='noopener noreferrer'
      >
        <li>MY HIVE</li>
      </a>
      {<a
        href='https://yield.socialbees.io'
        target='_blank'
        rel='noopener noreferrer'
      >
        <li>$MONSTER Yield</li>
      </a>}

      <div className='socials'>
        <ul>
          <a
            href='https://opensea.io/collection/social-bees-university'
            target='_blank'
            rel='noopener noreferrer'
          >
            <li>
              <OpenSeaLogo />
              OpenSea
            </li>
          </a>
          <a
            href='https://discord.gg/4v3CZn5aJm'
            target='_blank'
            rel='noopener noreferrer'
          >
            <li>
              <RiDiscordFill id='discord' />
              Discord
            </li>
          </a>
          <a
            href='https://www.youtube.com/c/BeesSocialTV'
            target='_blank'
            rel='noopener noreferrer'
          >
            <li>
              <FaYoutube id='youtube' />
              Youtube
            </li>
          </a>
          <a
            href='https://twitter.com/Crypto_Swarm'
            target='_blank'
            rel='noopener noreferrer'
          >
            <li>
              <RiTwitterFill id='twitter' />
              Twitter
            </li>
          </a>
          <a
            href='https://medium.com/bees-social'
            target='_blank'
            rel='noopener noreferrer'
          >
            <li>
              <AiFillMediumSquare id='medium' />
              Medium
            </li>
          </a>
        </ul>

        <div className='wallet-container'>
          {!account.account.address ||
          account.account.address === "" ||
          account.account.address ===
            "0x93CaD765A32A68A13f9677638a578AC2a92d2d5C" ? (
            <Button
              className='wallet-btn__disconnected'
              onClick={handleWalletModal}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className='wallet-btn__connected'
              onClick={handleWalletModal}
            >
              <span className='wallet-btn__connected__container'>
                <div className='left'>
                  <img
                    src={require("../../assets/metamask.png").default}
                    alt=''
                  />
                  {ethBalance} {""}
                  ETH
                </div>
                {account.account.address.substr(0, 5)}...
                {account.account.address.substr(
                  account.account.address.length - 4,
                  account.account.address.length
                )}
              </span>
            </Button>
          )}
        </div>
      </div>
      <WalletModal open={open} handleClose={handleWalletModal} />
    </Menu>
  );
};

export default SideBar;
