import { Button } from '../atoms';
import { FaDiscord } from 'react-icons/fa';
import { ReactComponent as OpenSeaLogo } from '../../assets/opensea-logo.svg';

const SBU = () => {
  return (
    <div className="sbu-section">
      <header className="sbu-section__header">
        <h3>SBU DAO</h3>
      </header>
      <p>
          The financially savvy <b>Social BEES</b> came to Earth to address human
          financial struggles.
      </p>
      <p>By promoting prosperity through their powerful solution: <br/> The <b>SBU DAO, powered by</b> the potent <b>$BHNY Token!</b></p>

      <div className="sbu-section__link">
        <br/>
        <p>
          Join the Hive by buying a BEE from Opensea or by winning in the daily
          auction!
        </p>
        <a
          href="https://opensea.io/collection/social-bees-university"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>
            <OpenSeaLogo />
            Opensea
          </Button>
        </a>
      </div>

    </div>
  );
};

export default SBU;
