import React from "react";

import moment from "moment";
import { FiClock } from "react-icons/fi";
import { Button, CountDown } from "../atoms";

interface Props {
  firstAuctionStart: boolean;
  setFirstAuctionStart: any;
  timeStart: any;
}

const AuctionStarts = ({
  firstAuctionStart,
  setFirstAuctionStart,
  timeStart,
}: Props) => {
  return (
    <div className='auction-section__content__ended'>
      <header>Auction starts in</header>

      <div className='time-remaining'>
        <FiClock />

        <CountDown
          targetDate={moment.unix(1654223400)}
          setCountDownEnded={setFirstAuctionStart}
        />
      </div>
    </div>
  );
};

export default AuctionStarts;
