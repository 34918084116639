import { useEffect, useState } from "react";
import { Button } from "../atoms";
import hny from "../../assets/HNY.png";
import { IAccount } from "../../types";
import { Dispatch } from "redux";
import { useAccount } from "../../hooks/useAccount";
import { parseNumbers } from "../../utils/currency";
import { useDispatch } from "react-redux";
import { WalletModal } from "../molecules";
import { useMediaQuery } from "react-responsive";
import {
  buyHoneyWithWPE,
  getAmountOut,
  buyHoneyWithWETH,
  getWpeBalance,
  getGasEstimate,
} from "../../lib/utils";
import { useGetEthBalance } from "../../hooks/useGetEthBalance";
import { setToast } from "../../store/actions/Toast";

import * as ethers from "ethers";
const Honey = () => {
  const [token, setToken] = useState<string>("ETH");
  const [wpeBalance, setWpeBalance] = useState<any>(0);
  const [ethBalance, setEthBalance] = useState<any>(0);
  const [amountIn, setAmountIn] = useState("0");
  const [amountOut, setAmountOut] = useState<any>("0");
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [gasAmount, setGasAmount] = useState("0");
  const [open, setOpen] = useState(false);
  const _ethBalance = useGetEthBalance();
  let _account: IAccount = useAccount();
  const { ...account } = _account as any;
  const dispatch: Dispatch<any> = useDispatch();
  const handleWalletModal = () => setOpen(!open);
  const onTokenChange = (e: any) => {
    setToken(e.target.value);
  };

  const _getAmoutOut = async () => {
    const parsedAmountIn = ethers.utils.parseEther(amountIn || "0");

    const _amountOut: any = await getAmountOut(
      parsedAmountIn,
      token,
      account.account.address
    );

    setAmountOut(_amountOut.toString());
  };

  const _buyHoneyWithWPE = async () => {
    try {
      const swap = await buyHoneyWithWPE(amountIn, account.account.address);

      dispatch(
        setToast({
          visible: true,
          message: "Transaction submitted",
          type: "success",
          etherLink: `https://etherscan.io/tx/${swap.hash}`,
        })
      );
      setAmountIn("0");
      setAmountOut("0");
    } catch (err) {
      console.log("ERROR:::", err);
      dispatch(
        setToast({
          visible: true,
          message: "There was an error while submitting your transaction.",
          type: "error",
        })
      );
    }
  };

  const _buyHoneyWithETH = async () => {
    if (Number(gasAmount) > Number(ethBalance)) {
      return dispatch(
        setToast({
          visible: true,
          message: "Insufficient funds : amount is lower than gas fee",
          type: "error",
        })
      );
    }

    try {
      const swap = await buyHoneyWithWETH(amountIn, account.account.address);

      dispatch(
        setToast({
          visible: true,
          message: "Transaction submitted",
          type: "success",
          etherLink: `https://etherscan.io/tx/${swap.hash}`,
        })
      );

      setAmountIn("0");
    } catch (err) {
      console.log("ERROR:::", err);
      dispatch(
        setToast({
          visible: true,
          message: "There was an error while submitting your transaction.",
          type: "error",
        })
      );
    }
  };

  async function _setEthBalance() {
    const b: any = await _ethBalance;
    setEthBalance(parseNumbers(b));
  }

  const setMaxTokenBalance = async () => {
    if (token === "ETH") {
      const gas: any = await getGasEstimate(account.account.address);

      setGasAmount(gas);

      const max = Number(ethBalance) - gas;

      if (max <= 0) {
        return setAmountIn(ethBalance);
      }

      return setAmountIn(max.toString());
    }

    return setAmountIn(wpeBalance);
  };

  useEffect(() => {
    const _getWPEBalance = async () => {
      const balance = await getWpeBalance(account.account);

      setWpeBalance(balance);
    };

    if (account.account.address !== "") {
      _getWPEBalance();
    } else {
      setWpeBalance(0);
    }
  }, [token, account.account.address]);

  useEffect(() => {
    _setEthBalance();
  });

  useEffect(() => {
    if (amountIn !== "0") {
      _getAmoutOut();
    }
  }, [amountIn, token, account.account.address]);

  // useEffect(() => {
  //   setInterval(function () {
  //     _getAmoutOut();
  //   }, 10000);
  // }, []);

  return (
    <section className='honey-section'>
      <header>
        <img src={hny} alt='honey' />
        <h3>BUY BHNY</h3>
      </header>

      <div className='honey-section__info'>
        <p>
          BHNY is the core token in the BEES ecosystem. It's needed for
          governance, farming and utility.
        </p>
        <div className='view-more'>
          <a
            style={{ color: "inherit", textDecoration: " none" }}
            href='https://sbudao.notion.site/Social-BEES-University-DAO-7211d88acfb2413991a98dc1364b77c1'
            target='_blank'
            rel='noopener noreferrer'
          >
            View More
          </a>
        </div>

        <div className='honey-container'>
          {/* <div className='coming-soon'>
            <header>Coming soon!</header>
          </div> */}

          <div className='buy-honey'>
            <div className='actions'>
              <input
                type='number'
                placeholder='0.0'
                value={amountIn}
                onChange={(e: any) => setAmountIn(e.target.value)}
              />
              <select name='select' onChange={onTokenChange}>
                <option value='ETH'>ETH</option>
                <option value='WPE'>WPE</option>
              </select>
              {!isMobile && (
                <Button
                  onClick={
                    account.account.address !== ""
                      ? setMaxTokenBalance
                      : () => {}
                  }
                >
                  Max
                </Button>
              )}
            </div>

            {isMobile && (
              <Button
                onClick={
                  account.account.address !== ""
                    ? setMaxTokenBalance
                    : () => null
                }
              >
                Max
              </Button>
            )}

            <p className='result'>
              {/* {token === "ETH"
                ? prevAmountOut
                : ethers.utils.formatEther(prevAmountOut)} */}
              {Number(amountOut).toFixed(6).toLocaleString()}
              <small>BHNY</small>
            </p>
          </div>

          <div className='honey-section__action'>
            {account.account.address === "" ? (
              <Button onClick={() => setOpen(true)}>Buy</Button>
            ) : (
              <Button
                onClick={token === "ETH" ? _buyHoneyWithETH : _buyHoneyWithWPE}
              >
                Buy
              </Button>
            )}
          </div>
        </div>
      </div>
      <WalletModal open={open} handleClose={handleWalletModal} />
    </section>
  );
};

export default Honey;
