import React from "react";
import { ReactComponent as OpenSeaLogo } from "../../assets/opensea-logo.svg";
import { RiTwitterFill, RiDiscordFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className='Footer'>
      <ul>
        <li>
          <a
            href='https://opensea.io/collection/social-bees-university'
            target='_blank'
            rel='noopener noreferrer'
          >
            <OpenSeaLogo />
          </a>
        </li>
        <li>
          <a
            href='https://discord.gg/4v3CZn5aJm'
            target='_blank'
            rel='noopener noreferrer'
          >
            <RiDiscordFill id='discord' />
          </a>
        </li>
        <li>
          <a
            href='https://www.youtube.com/c/BeesSocialTV'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FaYoutube id='youtube' />
          </a>
        </li>
        <li>
          <a
            href='https://twitter.com/Crypto_Swarm'
            target='_blank'
            rel='noopener noreferrer'
          >
            <RiTwitterFill id='twitter' />
          </a>
        </li>
        <li>
          <a
            href='https://medium.com/bees-social'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AiFillMediumSquare id='medium' />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
