import React from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { IToast } from "../../types";
import { setToast } from "../../store/actions/Toast";
import { Alert, Grow, Button } from "@mui/material";
import { useToast } from "../../hooks/useToast";

const Toast = () => {
  const _message = useToast();
  const { ...message } = _message as any;

  const dispatch: Dispatch<any> = useDispatch();
  const _setMessage = React.useCallback(
    (message: IToast) => dispatch(setToast(message)),
    [dispatch]
  );

  const timeToCloseAlert = () => {
    setTimeout(() => {
      _setMessage({ visible: false, message: "", type: "" });
    }, 8000);
  };

  React.useEffect(() => {
    if (message.toast.visible) {
      timeToCloseAlert();
    }
  }, [message]);

  return (
    message.toast.visible && (
      <Grow in={message.toast.visible}>
        <Alert
          onClick={() => _setMessage({ visible: false, message: "", type: "" })}
          severity={message.toast.type}
          className='alert__container'
          onClose={() => _setMessage({ visible: false, message: "", type: "" })}
          // action={
          //   message.toast.type === 'success' && (
          //     <Button
          //       href={message.toast.etherLink}
          //       target="_blank"
          //       rel="noreferrer"
          //       color="inherit"
          //       size="small"
          //     >
          //       View in Etherscan
          //     </Button>
          //   )
          // }
        >
          {message.toast.message}
          {message.toast.etherLink && (
            <a
              href={message.toast.etherLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              View on Etherscan
            </a>
          )}
        </Alert>
      </Grow>
    )
  );
};

export default Toast;
