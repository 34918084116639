import styled from "styled-components";

export const Approving = styled.div`
  background-color: #000;
  min-width: 400px;
  padding: 1rem 2rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .css-18lrjg1-MuiCircularProgress-root {
    width: 80px !important;
    height: 80px !important;
  }

  p {
    font-size: 25px;
  }

  strong {
    color: #f6a917;
  }

  svg {
    color: #f6a917;
  }
`;

export const StyledBeeCard = styled.div`
  background-color: peru;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0.8rem 1rem;

  @media (max-width: 493px) {
    justify-content: unset;
    align-items: center;
    flex-direction: column;
  }
`;

export const BeeCardImageContainer = styled.div`
  width: 100%;
  max-width: 207px;
  img {
    width: 100%;
  }
`;

export const BeeCardInfoContainer = styled.div`
  header {
    color: peru;
    font-size: 25px;
    font-weight: 800;
  }

  .current-bid {
    margin: 0.5rem 0;
    header {
      cursor: unset;
      color: #fff;
      font-size: 10px;
      font-weight: unset;
    }
  }
`;

export const PlaceBid = styled.div`
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 10px;
  input {
    min-height: 45px;
    border: none;
    padding: 5px 0.5rem;

    &:focus-visible {
      outline: none;
    }
  }

  button {
    min-width: 100px;
    min-height: 30px;
    max-height: 45;
    width: auto;
    white-space: nowrap;
    border-radius: unset;
  }

  .spinner-bid {
    color: #fff;
  }
`;

export const BidHistory = styled.div`
  margin-top: 10px;
  header {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    color: #fff;
    strong {
      cursor: pointer;
      color: #fff;
    }
  }
`;

export const Bid = styled.div`
  /* display: flex;
  justify-content: space-between; */

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  border-top: 0.5px solid #fff;
  padding-top: 5px;
  margin-top: 5px;

  p {
    margin: unset;
  }

  span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledBeeModal = styled.div`
  background-color: #000;
  padding: 1rem 2rem;
  border-radius: 15px;
  header {
    display: flex;
    gap: 20px;

    .bee-img {
      max-width: 100px;

      img {
        width: 100%;
      }
    }

    .current-bid {
      display: flex;

      gap: 15px;

      .biid {
      }
    }

    .bee-info {
      &__header {
        color: #f6a917;
        font-size: 35px;
        font-weight: 600;
      }
      small {
        font-size: 13px;
        font-weight: 100;
      }

      strong {
        display: block;
        font-size: 24px;
        font-weight: 800;
      }
    }
  }

  @media screen and (max-width: 586px) {
    header {
      flex-direction: column;
      .bee-img {
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: unset;

        img {
          width: 100%;
          max-width: 200px;
        }
      }
    }
  }
`;
