import Web3 from "web3";
import { setBlockchain } from "../store/actions/blockchain";
import moment from "moment";
import { SUPPORTED_WALLETS } from "../constants/wallets";

import { UnsupportedChainIdError } from "@web3-react/core";

import { MAINNET_URLS } from "../constants/chains";

import * as ethers from "ethers";
import config from "../config";
import { parseNumbers } from "../utils/currency";

export const configure = async (provider: any) => {
  const web3 = new Web3(provider);
  const currentBlock = await web3.eth.getBlockNumber();
  setBlockchain({ currentBlock });
};

export const connectOnMount = (
  key: string | null,
  activate: any,
  setAccount: any
) => {
  const { removeItem } = localStorage;

  const wallets = Object.values(SUPPORTED_WALLETS);

  if (key) {
    const currentWallet = wallets.find((w) => w.name === key);
    const connector = currentWallet?.connector;

    connector &&
      activate(connector, undefined, true)
        .then(async () => {
          localStorage.setItem("connected", "true");
          localStorage.setItem("wconnected", currentWallet.name);
          const walletAddress = await connector.getAccount();
          setAccount({
            address: walletAddress,
            provider:
              currentWallet.name === "Coinbase Wallet"
                ? connector.provider
                : connector,
          });
        })
        .catch((error: any) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector);
          } else {
          }
        });
  } else {
    // set Account({
    //   address: "0x93CaD765A32A68A13f9677638a578AC2a92d2d5C",
    //   provider: web3,
    // });
  }
};

export const getAmountOut = async (
  amountIn: any,
  token: string,
  account?: string
) => {
  try {
    if (Number(amountIn.toString()) === 0) return 0;

    let provider = null;
    let routerContract = null;
    let contract = null;
    let finalAmount2: any = 0;
    const path = [config.WETHAddress, config.WPEAdrress, config.honeyAddress];

    const contractAddress =
      token === "WPE" ? config.lpToken : config.ethAddress;

    if (account !== "" && window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum as any);
      contract = new ethers.Contract(
        contractAddress,
        config.pairAbi,
        provider.getSigner()
      );

      routerContract = new ethers.Contract(
        config.routerAddress,
        config.routerAbi,
        provider.getSigner()
      );
    } else {
      provider = new ethers.providers.JsonRpcProvider(
        "https://mainnet.infura.io/v3/799ad57d4067419db57bf9592b471301"
      );

      routerContract = new ethers.Contract(
        config.routerAddress,
        config.routerAbi,
        provider
      );
    }

    if (token === "WPE") {
      contract = new ethers.Contract(contractAddress, config.pairAbi, provider);
      const reserves = await contract.getReserves();

      const reserveIn = reserves.reserve0.toString();
      const reserveOut = reserves.reserve1.toString();
      const _reserves =
        config.wpeAddress === (await contract.token0())
          ? [reserveIn, reserveOut]
          : [reserveOut, reserveIn];

      finalAmount2 = await wpeAmountOut(
        amountIn,
        [config.WPEAdrress, config.honeyAddress],
        routerContract
      );

      finalAmount2 = finalAmount2[1];

      finalAmount2 = ethers.utils.formatEther(finalAmount2.toString());
      const tax = (11 / 100) * Number(finalAmount2);

      finalAmount2 = Number(finalAmount2 - tax);
    } else {
      finalAmount2 = await routerContract.getAmountsOut(amountIn, path);

      const tax = ((11 / 100) *
        Number(
          ethers.utils.formatEther(
            finalAmount2[finalAmount2.length - 1].toString()
          )
        )) as any;
      finalAmount2 =
        (Number(
          ethers.utils.formatEther(
            finalAmount2[finalAmount2.length - 1].toString()
          )
        ) as any) - tax;
    }

    return finalAmount2;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

async function wpeAmountOut(amountIn: any, path: any, routerContract: any) {
  return await routerContract.getAmountsOut(amountIn, path);
}

export const buyHoneyWithWPE = async (amountIn: string, account: string) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum as any);

  const signer = provider.getSigner();
  const signerAccount = await signer.getAddress();

  const parsedAmountIn = ethers.utils.parseEther(amountIn);

  try {
    //contracts
    const routerContract = new ethers.Contract(
      config.routerAddress,
      config.routerAbi,
      signer
    );

    const erc20Allowance = new ethers.Contract(
      config.wpeAddress,
      config.wpeAbi,
      signer
    );

    const pairContract = new ethers.Contract(
      config.lpToken,
      config.pairAbi,
      provider.getSigner()
    );

    // const reserves = await pairContract.getReserves();

    // const reserveIn = reserves.reserve0.toString();
    // const reserveOut = reserves.reserve1.toString();

    // const minAmountOut2 = await wpeAmountOut(
    //   parsedAmountIn,
    //   reserveIn,
    //   reserveOut,
    //   routerContract
    // );

    const amountOut = await getAmountOut(parsedAmountIn, "WPE", signerAccount);

    // const tax = ((20 / 100) *
    //   Number(ethers.utils.formatEther(minAmountOut2.toString()))) as any;

    const path = [config.WPEAdrress, config.honeyAddress];

    // console.log(Number(ethers.utils.formatEther(amountOut.toString())));

    const allowance = await erc20Allowance.allowance(
      signerAccount,
      config.routerAddress
    );
    let approve = null;

    if (allowance.toString() === "0") {
      approve = await erc20Allowance.approve(
        config.routerAddress,
        "300000000000000000000000"
      );
    }

    const swap =
      await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        parsedAmountIn.toString(),
        ethers.utils.parseEther(amountOut.toString()),
        path,
        signerAccount,
        Math.floor(Date.now() / 1000) + 100
      );
    return swap;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const buyHoneyWithWETH = async (amountIn: string, account: string) => {

  const provider = new ethers.providers.Web3Provider(window.ethereum as any);

  const signer = provider.getSigner();

  const signerAccount = await signer.getAddress();

  const parsedAmountIn = ethers.utils.parseEther(amountIn);

  try {
    const routerContract = new ethers.Contract(
      config.routerAddress,
      config.routerAbi,
      signer
    );

    const path = [config.WETHAddress, config.WPEAdrress, config.honeyAddress];

    const minAmountOut: any = await routerContract.getAmountsOut(
      parsedAmountIn,
      path
    );

    const tax = ((11 / 100) *
      Number(
        ethers.utils.formatEther(
          minAmountOut[minAmountOut.length - 1].toString()
        )
      )) as any;

    const finalMinAmountOut =
      (Number(
        ethers.utils.formatEther(
          minAmountOut[minAmountOut.length - 1].toString()
        )
      ) as any) - tax;

    const swap =
      await routerContract.swapExactETHForTokensSupportingFeeOnTransferTokens(
        ethers.utils.parseEther(finalMinAmountOut.toString()),
        path,
        signerAccount,
        Math.floor(Date.now() / 1000) + 100,
        {
          value: parsedAmountIn,
        }
      );
    return swap;
  } catch (err) {
    throw err;
  }
};

export const getWpeBalance = async (account: any) => {
  if (account.address !== "") {
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum as any
      );

      const signer = provider.getSigner();
      const erc20Contract = new ethers.Contract(
        config.wpeAddress,
        config.wpeAbi,
        signer
      );

      const balance = await erc20Contract.balanceOf(account.address);

      return ethers.utils.formatUnits(balance.toString(), "ether");
    } catch (err) {
      console.log(err);
      return 0;
    }
  }
};

export const getGasEstimate = async (from: string) => {
  let res: any = null;

  const web3 = new Web3(window.ethereum as any);

  const contract = new web3.eth.Contract(
    config.routerAbi as any,
    config.routerAddress
  );

  try {
    const balance = await web3.eth.getBalance(from);
    // convert to ether
    const balanceInEth = web3.utils.fromWei(balance, "ether");

    const path = [config.WETHAddress, config.WPEAdrress, config.honeyAddress];
    await contract.methods
      .swapExactETHForTokensSupportingFeeOnTransferTokens(
        web3.utils.toWei(balanceInEth),
        path,
        from,
        Math.floor(Date.now() / 1000) + 100
      )
      .estimateGas(
        { from: from, value: web3.utils.toWei(balanceInEth) },
        async (error: any, result: any) => {
          res = await result;
        }
      );

    let unitprice = await web3.eth.getGasPrice();

    let d = parseInt(res) * parseInt(unitprice) * 1.4;

    let ethercost = await web3.utils.fromWei(`${d}`, "ether");

    return ethercost ? Number(ethercost) : 0;
  } catch (error) {
    console.log(error);
  }
};
