import { useEffect, useState } from "react";

import { ApprovalBody } from "../molecules";
import { Button } from "../atoms";
import { Modal } from "../atoms";
import { StyledBeeModal, BidHistory, PlaceBid } from "./Bid.styles";

import Bid from "./Bid";
import beeimg from "../../assets/placeholder-bee.png";
import { RiShareBoxLine } from "react-icons/ri";

import { setToast } from "../../store/actions/Toast";

import { useDispatch } from "react-redux";

import * as ethers from "ethers";

import CircularProgress from "@mui/material/CircularProgress";

import config from "../../config";

import { useAccount } from "../../hooks/useAccount";
import { IAccount } from "../../types";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bee: number;
  bids: any;
  currentBid: string;
  idx: number;
  auctionDay: number;
  handleAuctions: any;
  getBids: any;
  fee: string;
}

const BidModal = ({
  open,
  setOpen,
  bee,
  bids,
  currentBid,
  idx,
  getBids,
  fee,
}: IProps) => {
  const [amount, setAmount] = useState(fee);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [approving, setApproving] = useState(false);

  const _account: IAccount = useAccount();
  const { ...account } = _account as any;
  const [refreshBids, setRefreshBids] = useState(false);

  const onPlaceBid = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);

    const signer = await provider.getSigner();

    if (Number(amount) <= Number(fee)) {
      return dispatch(
        setToast({
          visible: true,
          message: "Amount must to be greater than fee: " + fee,
          type: "error",
        })
      );
    }

    const contract = new ethers.Contract(
      config.auctionAddress,
      config.auctionAbi,
      signer
    );

    const wpeContract = new ethers.Contract(
      config.wpeAddress,
      config.wpeAbi,
      signer
    );

    setLoading(true);

    try {
      const erc20Allowance = await wpeContract.allowance(
        account.account.address,
        config.auctionAddress
      );

      console.log("erc20Allowance", erc20Allowance.toString());

      const _allowance = ethers.utils.formatEther(erc20Allowance.toString());

      if (Number(amount) > Number(_allowance)) {
        setApproving(true);
        const approve = await wpeContract.approve(
          config.auctionAddress,
          "300000000000000000000000000000"
        );

        const receipt = await approve.wait();

        console.log("receipt", receipt);
        console.log("allowance", erc20Allowance.toString());

        setApproving(false);
      }

      const parseAmount = ethers.utils.parseEther(amount);

      const placeBid = await contract.placeBid(parseAmount, idx);

      listenBid();

      if (placeBid) setLoading(false);
      setAmount(fee);

      dispatch(
        setToast({
          visible: true,
          message: "Transaction submitted",
          type: "success",
          etherLink: `https://etherscan.io/tx/${placeBid.hash}`,
        })
      );
    } catch (err: any) {
      setApproving(false);
      setLoading(false);
      console.log(err);

      if (err?.error?.message) {
        if (
          err.error.message ===
          "execution reverted: you cannot bid a lower amount than the actual higher"
        ) {
          return dispatch(
            setToast({
              visible: true,
              message: "Your bid must be higher than the current bid",

              type: "error",
            })
          );
        }
      }

      if (err.code === "INSUFFICIENT_FUNDS") {
        return dispatch(
          setToast({
            visible: true,
            message: "Insufficient funds",
            type: "error",
          })
        );
      }

      dispatch(
        setToast({
          visible: true,
          message: "There was an error while submitting your transaction.",
          type: "error",
        })
      );
    }
  };

  function listenBid() {
    try {
      console.log("Listener started");
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "https://rinkeby.infura.io/v3/799ad57d4067419db57bf9592b471301"
      // );
      const provider = new ethers.providers.Web3Provider(
        window.ethereum as any
      );
      const auctionContract = new ethers.Contract(
        config.auctionAddress,
        config.auctionAbi,
        provider
      );

      const filter = auctionContract.filters.LogBid(account.account.address);

      console.log("watching for events...");
      // Receive an event when that filter occurs
      auctionContract.on(filter, async (from, to, amount, event, unknow) => {
        await getBids();
        setRefreshBids(!refreshBids);
        console.log("event triggered", { from, to, amount, event, unknow });
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setApproving(false);
    setLoading(false);
  }, [open]);

  return (
    <Modal isOpen={open} setIsOpen={setOpen} closable>
      {approving ? (
        <ApprovalBody />
      ) : (
        <StyledBeeModal>
          <header>
            <section className='bee-img'>
              <img
                src={`https://socialbees-mint-img.s3.amazonaws.com/${bee}.png`}
                alt={`bee-${bee}`}
              />
            </section>
            <section className='bee-info'>
              <div className='bee-info__header'>Bee #{bee}</div>
              <div className='current-bid'>
                <div className='biid'>
                  <small>Current Bid</small>
                  <strong>{currentBid}</strong>
                </div>
              </div>
              <PlaceBid>
                <input
                  type='number'
                  placeholder='Enter amount'
                  value={amount}
                  min={fee}
                  step='0.1'
                  onChange={(e: any) => setAmount(e.target.value)}
                />
                <Button
                  onClick={() => onPlaceBid()}
                  disabled={amount !== "" ? false : true}
                >
                  {loading ? (
                    <CircularProgress className='spinner-bid' />
                  ) : (
                    "Place Bid"
                  )}
                </Button>
              </PlaceBid>
            </section>
          </header>
          <BidHistory>
            <header>Bid History</header>
            {bids.length > 0 ? (
              bids.map((bid: any, index: number) => (
                <Bid
                  key={index}
                  bid={bid}
                  idx={idx}
                  refreshBids={refreshBids}
                />
              ))
            ) : (
              <p style={{ textAlign: "center" }}>
                <strong>No bids yet</strong>{" "}
              </p>
            )}
          </BidHistory>
        </StyledBeeModal>
      )}
    </Modal>
  );
};

export default BidModal;
