import Web3 from "web3";

import * as ethers from "ethers";

import { useAccount } from "../hooks/useAccount";

export const useGetEthBalance = async () => {
  const _account = useAccount();
  const { ...account } = _account as any;

  // const web3: Web3 = new Web3(account.account.provider);

  if (
    !window.ethereum ||
    !window.web3 ||
    account.account.address === "" ||
    account.account.address === "0x93CaD765A32A68A13f9677638a578AC2a92d2d5C"
  )
    return;

  await window.web3.currentProvider.enable();
  const web3: Web3 = new Web3(window.web3.currentProvider);

  try {
    const balance = await web3.eth.getBalance(
      web3.utils.toChecksumAddress(account.account.address)
    );

    return parseFloat(web3.utils.fromWei(balance.toString(), "ether")).toFixed(
      4
    );
  } catch (error) {
    console.error("error", error);
    return 0;
  }
};
