import { useEffect, useState } from "react";
import {
  StyledBeeModal,
  BidHistory,
  Bid as SBid,
  PlaceBid,
} from "./Bid.styles";
import { RiShareBoxLine } from "react-icons/ri";

import moment from "moment";
import * as ethers from "ethers";

import config from "../../config";

const Bid = ({ bid, refreshBids, idx }: any) => {
  const [bidAmount, setBidAmount] = useState("-");
  const [time, setTime] = useState("");

  useEffect(() => {
    try {
      const _bid = bid.map((b: any) => parseInt(b._hex, 16));
      const formatTimeStamp = moment.unix(_bid[_bid.length - 1]).format();
      setTime(moment(formatTimeStamp).fromNow());
      setBidAmount(ethers.utils.formatEther(bid[1]));
    } catch (err) {
      console.error(err);
    }
  }, [bid]);

  return (
    <SBid>
      <p>
        {" "}
        {bid[0]._hex.substr(0, 5)}...
        {bid[0]._hex.substr(bid[0]._hex.length - 4, bid[0]._hex.length)}
      </p>
      <p>{bidAmount}</p>
      <a
        href={`https://etherscan.io/address/${config.auctionAddress}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span>
          {time} <RiShareBoxLine />
        </span>
      </a>
    </SBid>
  );
};

export default Bid;
