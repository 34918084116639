import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import styledComponent from "styled-components";
import Grow from "@mui/material/Grow";

interface CustomButtonProps extends ButtonProps {
  tooltip?: boolean;
  tooltipText?: string;
}

const StyledButton = styled(Button)((props: any) => ({
  padding: "5px 20px",
  backgroundColor: props.customColor ? props.customColor : "#f6a917",
  boxShadow: "none",
  textTransform: "unset",
  fontFamily: "Poppins",
  color: "#fff",
  borderRadius: "10px",
  position: "relative",
  "&:hover": {
    backgroundColor: "#ce8b0f",
    boxShadow: "none",
  },

  "&:disabled": {
    background: "#7f849b",
    color: "#fff",
  },
}));

const ToolTip = styledComponent.div`
  min-width: 300px;
  padding: 10px;
  position: absolute;
  background: #000000b9;
  border-radius: 10px;
  top : -100px;
`;

const CustomButton: React.FC<CustomButtonProps> = ({ children, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const _showTooltip = () => {
    if (props.tooltip) {
      return setShowTooltip(true);
    }

    return null;
  };

  const hideTooltip = () => {
    if (props.tooltip) {
      return setShowTooltip(false);
    }

    return null;
  };

  return (
    <>
      <StyledButton
        {...props}
        onMouseOver={_showTooltip}
        onMouseOut={hideTooltip}
      >
        {props.tooltip && (
          <Grow in={showTooltip}>
            <ToolTip>{props.tooltipText}</ToolTip>
          </Grow>
        )}
        {children}
      </StyledButton>
    </>
  );
};

export default CustomButton;
