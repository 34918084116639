import auctionAbi from "./abis/MintAuction2";
import oldAuctionAbi from "./abis/OldAuctionAbi";
import beeNFTAbi from "./abis/beeNFTAbi";
import wpeAbi from "./abis/wpeAbi";

import honeyBeeLockUpABI from "./abis/HoneyBeeLockup";

import routerAbi from "./abis/routerV2ABI";
import pairAbi from "./abis/pairABI";

import bhnyAbi from "./abis/Erc20";

//rinkeny 1
// auctionAddress: "0x008b0d4f038825365D59c829291838c3804DC6B9",

// BeeNFT deployed at: 0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512
// HoneyToken deployed at: 0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0
// Auction deployed at: 0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9

// const config = {
//   auctionAbi,
//   auctionAddress: "0x694fB67C31Ee713bF121886F1532a9df488B0476",

//   beeNFTAbi,
//   beeNFTAddress: "0xdA3a036D1A26A391Cd3c7A8B3DE81E948CB813Da",

//   wpeAbi,
//   wpeAddress: "0x2A8e0D40502a5307088545218e1C385d505aCa1b",

//   WPEAdrress: "0x2A8e0D40502a5307088545218e1C385d505aCa1b",
//   // honeyAddress: "0x5b05ef471Cfee9416EE8f47AFF28a24C5E0d728f",
//   // honeyAddress: "0x9C2bde7c2DA9dD929edcE5dAb45005a55f573F79",
//   honeyAddress: "0xa6Ca823582482237833EA685d788d17758bf8932",
//   routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
//   // lpToken: "0x5DaB668FF7953Cc0d84F1855C0EA7A00062fc45E",
//   lpToken: "0x9189dead3F53BE03aBcFE659b934DF70cE2DA9ee",
//   WETHAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
//   ethAddress: "0x66c0f047225ffD85f530880Cfa5F8F1e9fb32ee5",

// // Funny Token: 0x594B55aBA1467ce2b11E811aF99d885007bF42F0
// // LP WPE/Funny: 0xfcdbc3d56931f71f5cddb2f83034069fd3aa5110
// // WPE: 0xd075e95423C5c4BA1E122CaE0f4CdFA19b82881b

//   routerAbi,
//   pairAbi,
// };

//Prod

// BeeNFT deployed at: 0x809d550fca64d94Bd9F66E60752A544199cfAC3D
// HoneyToken deployed at: 0x4c5859f0F772848b2D91F1D83E2Fe57935348029
// Auction deployed at: 0x1291Be112d480055DaFd8a610b7d1e203891C274
const config = {
  auctionAbi,
  auctionAddress: "0x14e519289050943e610c2917b13c1f097177EDf9",

  oldAuctionAbi,
  oldAuctionAddress: "0xC21b5d26FB9fd9FF60da66346790591A239775cf",

  beeNFTAbi,
  beeNFTAddress: "0x4848a07744e46bb3ea93ad4933075a4fa47b1162",

  //honey
  wpeAbi,
  wpeAddress: "0xCc802c45B55581713cEcd1Eb17BE9Ab7fcCb0844",

  honeyBeeLockUpABI,
  honeyBeeLockUpAddress: "0x131a7077A61Ff6E5B47d4A8398D84EdA12A9057C",

  WPEAdrress: "0xd075e95423C5c4BA1E122CaE0f4CdFA19b82881b",
  honeyAddress: "0xCc802c45B55581713cEcd1Eb17BE9Ab7fcCb0844",
  routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  lpToken: "0x9EC96dcB54331626B79d8450a3daa9BcFa02E0b0",
  WETHAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  ethAddress: "0xd075e95423C5c4BA1E122CaE0f4CdFA19b82881b",

  routerAbi,
  pairAbi,

  bhnyAbi,
  bhnyAddress: "0xCc802c45B55581713cEcd1Eb17BE9Ab7fcCb0844"
};

export default config;
