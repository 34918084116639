import React from 'react';
// import SliderComponent, { Settings } from 'react-slick';

// import logopixel from '../../assets/slider/Logo-Pixearth.png';

// import { Button } from '../atoms';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const settings: Settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: true,
// };

// const sliderOptions = [
//   {
//     title: 'The Mofo Goats Thinktank',
//     description:
//       'INNOVATORS & COLLABORATORS IN THE WEB 3.0 ENTERTAINMENT SPACE.',
//     color: '#1F6FCA',
//     name: 'goats',
//     website: 'https://www.goatsdao.com/',
//   },
//   {
//     title: 'YFU GIRLS',
//     description: 'A collection of unique YFUS who live on Ethereum blockchain.',
//     color: '#DA552B',
//     name: 'yfu',
//     website: 'https://anime.limited/',
//   },
//   {
//     // imgTitle: require("~/assets/slider/Logo-Pixearth.png").default(),
//     description:
//       'Get ready for the launch of the Portal to PixEarth, your door to the Metaverse Lands.',
//     color: '#62CC70',
//     name: 'pixel',
//     website: 'https://pix.earth/',
//   },
// ];

const Slider = () => {
  return (
    <div className="banner-wrapper">
      <div className='banner-background' />
    </div>
  );
  // return (
  //   <section className='slider-section'>
  //     <SliderComponent {...settings}>
  //       {sliderOptions.map((slide, index) => (
  //         <div key={index} className={`item ${slide.name}`}>
  //           <header>
  //             {slide.title ? (
  //               <>{slide.title}</>
  //             ) : (
  //               <img src={logopixel} alt={slide.name} />
  //             )}
  //           </header>

  //           <div className='item-info'>
  //             <p>{slide.description}</p>
  //           </div>

  //           <footer className='item-action'>
  //             <a href={slide.website} target='_blank' rel='noopener noreferrer'>
  //               <Button style={{ backgroundColor: slide.color }}>
  //                 Learn more
  //               </Button>
  //             </a>
  //           </footer>
  //         </div>
  //       ))}
  //     </SliderComponent>
  //   </section>
  // );
};

export default Slider;
