import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-precise-range-plugin";
import { useCountdown } from "../../hooks/useCountdown";

const DateTimeDisplay = ({ value, type, isDanger }: any) => {
  if (value) {
    return (
      <>
        {value}
        {type}
      </>
    );
  } else {
    return <>0{type}</>;
  }
};

const ShowCounter = ({ years, days, hours, minutes, seconds }: any) => {
  return (
    <p>
      <DateTimeDisplay value={hours} type={"h"} isDanger={false} />{" "}
      <DateTimeDisplay value={minutes} type={"m"} isDanger={false} />{" "}
      <DateTimeDisplay value={seconds} type={"s"} isDanger={false} />
    </p>
  );
};

const CountdownTimer = ({
  targetDate,
  setCountDownEnded,
  handleAuctions,
  setHandleAuctions,
  currentAuctionDay,
  block,
}: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate, block);

  if (days + hours + minutes + seconds <= 0) {
    setCountDownEnded(true);
    return <p>0h 0m 0s</p>;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
